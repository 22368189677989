var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"All questions ","no-body":"","name":"Add question"}},[_c('form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('validation-observer',{ref:"questionInfoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("question Details")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Enter the question Details. ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"title","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"placeholder":"question title"},model:{value:(_vm.question.title),callback:function ($$v) {_vm.$set(_vm.question, "title", $$v)},expression:"question.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"question type","label-for":"questiontype"}},[_c('validation-provider',{attrs:{"name":"question type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"questiontype","state":errors.length > 0 ? false : null,"options":_vm.options},model:{value:(_vm.question.type),callback:function ($$v) {_vm.$set(_vm.question, "type", $$v)},expression:"question.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"grad","label-for":"grad"}},[_c('validation-provider',{attrs:{"name":"grad","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"placeholder":"question title"},model:{value:(_vm.question.grad),callback:function ($$v) {_vm.$set(_vm.question, "grad", $$v)},expression:"question.grad"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"question Cover Image","label-for":"CorseCoverImage"}},[_c('validation-provider',{attrs:{"name":"CorseCoverImage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"CorseCoverImage","accept":"image/*"},model:{value:(_vm.question.imageCover),callback:function ($$v) {_vm.$set(_vm.question, "imageCover", $$v)},expression:"question.imageCover"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"hint","label-for":"hint"}},[_c('validation-provider',{attrs:{"name":"hint"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"hint","placeholder":"hint","state":errors.length > 0 ? false : null,"rows":"5"},model:{value:(_vm.question.hint),callback:function ($$v) {_vm.$set(_vm.question, "hint", $$v)},expression:"question.hint"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('addAnswerSection',{attrs:{"answers":_vm.question.answers}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2 w-100",attrs:{"variant":"success"},on:{"click":function($event){return _vm.handleOk()}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add The Question")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }